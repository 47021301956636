export default {
    setDefaultTypingSpeed(speed) {
        this.defaultTypingSpeed = speed;
        return this;
    },

    setTypingSpeed(speed) {
        this.typingSpeed = speed;
        return this;
    }
}